import {
  workPermitTypeInsideCanadaList,
  workPermitTypeList,
} from '@visto-tech/seed';
import backendClient from 'backend';
import logger from 'js-logger';
import { get, isEmpty } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { parseJSON } from 'utils/helpers';

import {
  Answer as RawAnswer,
  FeatureType,
  FormSubmissionStatus,
  UpdateAnswersData,
  UpdateAnswersOptions,
} from '../generated/graphql';

export type AnswerMap = {
  [Key: string]: string;
};

export class Answer {
  answerMap: AnswerMap;

  constructor(answers: any) {
    this.answerMap = answers.reduce((obj: any, answer: RawAnswer) => {
      const label = answer.question?.label;

      if (!label) return null;

      return {
        ...obj,
        [label]: answer.answer,
      };
    }, {});

    makeAutoObservable(this);
  }

  public static async getAnswers({
    questionLabels,
    accountId,
    personId,
    associations,
    status,
    feature,
  }: {
    questionLabels: string[];
    accountId?: number;
    personId?: number;
    associations?: { [key: string]: any };
    status?: FormSubmissionStatus;
    feature?: FeatureType;
  }) {
    let answers;

    try {
      answers = await backendClient.getAnswers({
        questionLabels,
        accountId,
        personId,
        associations: JSON.stringify(associations),
        status,
        feature,
      });
    } catch (err: any) {
      throw new Error(err);
    }

    if (!answers) return null;

    return new Answer(answers.data?.Answers);
  }

  static async getAdminAnswers({
    accountId,
    cursorId,
    personId,
    searchTerm,
    associations,
    status,
  }: {
    accountId: number;
    cursorId: number | null;
    personId: number | null;
    searchTerm: string | null;
    associations?: { [key: string]: any };
    status?: FormSubmissionStatus;
  }) {
    try {
      const response = await backendClient.getAnswersAdmin({
        accountId,
        cursor: cursorId,
        personId,
        searchTerm,
        associations: JSON.stringify(associations),
        status,
      });

      return response?.data?.AnswersAdmin;
    } catch (e) {
      logger.error(e);
    }
  }

  public static async updateAnswers(
    data: UpdateAnswersData[],
    personId?: number,
    accountId?: number,
    options?: UpdateAnswersOptions
  ) {
    try {
      return (
        await backendClient.updateAnswers({
          personId,
          accountId,
          data,
          options,
        })
      ).data?.updateAnswers;
    } catch (err) {
      logger.error(err);
    }
  }

  public get(label: string) {
    return this.answerMap[label] || '';
  }

  public getRepeater(label: string, index?: number, property?: string) {
    const parsed = parseJSON(get(this.answerMap, label));

    if (!parsed || isEmpty(parsed)) return '';

    if (!index || !property) return parsed;

    if (!parsed?.[index]?.[property]) return '';

    return parsed[index][property];
  }

  public static getLawyersProvince(lawyerAnswers: Answer | null) {
    if (
      !lawyerAnswers?.get('LAW_10_PROV') &&
      !lawyerAnswers?.get('LAW_10_STATE')
    ) {
      return lawyerAnswers?.get('LAW_10');
    }

    if (lawyerAnswers?.get('LAW_11') === 'Canada') {
      return lawyerAnswers?.get('LAW_10_PROV');
    }

    if (lawyerAnswers?.get('LAW_11') === 'United States of America') {
      return lawyerAnswers?.get('LAW_10_STATE');
    }

    return lawyerAnswers?.get('LAW_10') ?? '';
  }

  public static getSeedOptionLabelByValue(
    type: 'workPermitTypeOutside' | 'workPermitTypeInside' | 'workPermitType',
    value: string
  ) {
    const object = {
      workPermitTypeOutside: workPermitTypeList,
      workPermitTypeInside: workPermitTypeInsideCanadaList,
      workPermitType: [
        ...workPermitTypeList,
        ...workPermitTypeInsideCanadaList,
      ],
    };

    const options = get(object, type);

    if (!options) {
      return '';
    }

    const option = options.find((option) => option.value === value);

    if (!option) {
      return '';
    }

    return option.label;
  }
}

export default Answer;
